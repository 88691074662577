import * as React from 'react';

// Components
import { Error } from '../Components/Common/404'

const NotFoundPage = () => {
	return (
		<Error/>
  	)
}

export default NotFoundPage
